import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Card } from 'components/common';
import starIcon from 'assets/icons/star.svg';
import clockIcon from 'assets/icons/clock.svg';
import { Wrapper, Grid, Item, Content, Stats } from './styles';

export const RestaurantCard = ({ name, lastVisited, rating, time }) => (
  <Item>
    <Card>
      <Content>
        {name ? <h4>{name}</h4> : <h4>&nbsp;</h4>}
        {name ? (
          <p>
            Last Visted: <strong>{lastVisited}</strong>
          </p>
        ) : (
          <p>&nbsp;</p>
        )}
      </Content>
      <Stats>
        {name ? (
          <div>
            {Array.from(Array(rating || 0)).map((r, index) => (
              <img key={index} src={starIcon} alt="rating" />
            ))}
            <span>{rating || '?'}/5</span>
          </div>
        ) : (
          <div>
            <span>&nbsp;</span>
          </div>
        )}
        {name ? (
          <div>
            {Array.from(Array(time || 0)).map((r, index) => (
              <img key={index} src={clockIcon} alt="time" />
            ))}
            <span>{time || '?'}/3</span>
          </div>
        ) : (
          <div>
            <span>&nbsp;</span>
          </div>
        )}
      </Stats>
    </Card>
  </Item>
);

export const Projects = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allGoogleSheetSheet1Row {
          edges {
            node {
              id
              restaurants
              rating15
              time13ShortLong
              lastvisited
            }
          }
        }
      }
    `
  );
  const allRestaurants = data.allGoogleSheetSheet1Row.edges;
  return (
    <Wrapper as={Container} id="restaurants">
      <h2>All Restaurants</h2>
      <Grid>
        {allRestaurants.map(({ node }) => (
          <RestaurantCard
            key={node.id}
            name={node.restaurants}
            rating={node.rating15}
            lastVisited={node.lastvisited}
            time={node.time13ShortLong}
          />
        ))}
      </Grid>
    </Wrapper>
  );
};
