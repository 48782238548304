import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import food from 'assets/illustrations/breakfast.svg';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = () => (
  <Wrapper>
    <Header />
    <IntroWrapper as={Container}>
      <Details>
        <h1>Hungry Newport</h1>
        <h4>Where should I eat?</h4>
        <Button as={AnchorLink} href="#restaurants">
          Let's See
        </Button>
        &nbsp;
        <Button secondary="true" as={AnchorLink} href="#random">
          OMG Help
        </Button>
      </Details>
      <Thumbnail>
        <img src={food} alt="Food food food fooood" />
      </Thumbnail>
    </IntroWrapper>
  </Wrapper>
);
