import React, { useState } from 'react';
import { Container, Button } from 'components/common';
import question from 'assets/illustrations/questions.svg';
import { useStaticQuery, graphql } from 'gatsby';
import { useSpring, animated as a } from 'react-spring';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { RestaurantCard } from '../Projects/index';

export function Skills() {
  const data = useStaticQuery(
    graphql`
      query OtherQuery {
        allGoogleSheetSheet1Row {
          edges {
            node {
              id
              restaurants
              rating15
              time13ShortLong
              lastvisited
            }
          }
        }
      }
    `
  ).allGoogleSheetSheet1Row.edges;
  const [selected, setSelected] = useState({
    name: '',
    rating: '',
    lastVisited: '',
    time: '',
  });
  const [flipped, set] = useState(false);
  const { transform } = useSpring({
    transform: `perspective(600px) rotateX(${flipped ? 359 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const selectRandom = () => {
    const index = Math.floor(Math.random() * data.length);
    const { restaurants, rating15, lastvisited, time13ShortLong } = data[index].node;
    set(state => !state);
    setSelected({
      name: restaurants,
      rating: rating15,
      lastVisited: lastvisited,
      time: time13ShortLong,
    });
  };
  return (
    <Wrapper id="random">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={question} alt="Can't Decide?" />
        </Thumbnail>
        <Details>
          <h1>Can't Decide?</h1>
          <a.div className="card-container" style={{ transform }}>
            <RestaurantCard {...selected} />
          </a.div>
          <p>Let us help</p>

          <Button onClick={selectRandom}>Random</Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
}
